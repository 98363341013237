import React, { FC } from 'react';
import {
  AcuityScheduledAppointment,
  GetPatientUserDocument,
  GetSiteTrialsFromSiteStaffUserDocument,
  ListTrialSubjectsDocument,
  useCancelPatientAcuityAppointmentMutation,
} from '@fdha/graphql-api-sitestaff';
import {
  Typography,
  Button,
  Icon,
  useDialog,
  useSnackbar,
  useLoadingBar,
} from '@fdha/web-ui-library';
import { Paper, Stack, useTheme } from '@mui/material';
import { parseBackendError } from '@fdha/common-utils';
import { OnboardingCallInfo } from '@components';

interface OnboardingCallProps {
  onboardingCall: AcuityScheduledAppointment;
  patientId: string;
  isLoading: boolean;
}

const OnboardingCall: FC<OnboardingCallProps> = ({
  onboardingCall,
  patientId,
  isLoading,
}) => {
  const theme = useTheme();
  const { showSnackbarV2 } = useSnackbar();
  const { openDialogV2, closeDialog } = useDialog();
  const { showLoadingV2, hideLoading } = useLoadingBar();
  const [cancelAppointment] = useCancelPatientAcuityAppointmentMutation();

  const handleCancelAppointment = async () => {
    try {
      closeDialog();
      showLoadingV2();
      await cancelAppointment({
        variables: {
          patientId,
          appointmentId: onboardingCall?.id || 0,
        },
        refetchQueries: [
          GetPatientUserDocument,
          ListTrialSubjectsDocument,
          GetSiteTrialsFromSiteStaffUserDocument,
        ],
      });

      showSnackbarV2({
        message:
          'The onboarding call was canceled. Please, reschedule it as soon as possible.',
        severity: 'success',
      });
    } catch (error) {
      const message = parseBackendError(
        error,
        'Error canceling onboarding call'
      );

      showSnackbarV2({
        message,
        severity: 'error',
      });
    } finally {
      hideLoading();
    }
  };

  const handleConfirmCanceling = () => {
    openDialogV2({
      title: 'Cancel appointment?',
      content:
        "Are you sure you want to cancel this appointment? You’ll need to reschedule it, so that this subject can be included in the clinical trial.\n\nIf you encounter any issues, please don't hesitate to contact us.",
      cancelButtonLabel: 'Go back',
      confirmButtonLabel: 'Cancel appointment',
      handleConfirm: handleCancelAppointment,
    });
  };

  return (
    <Paper
      sx={{
        py: 3,
        px: 4,
      }}
    >
      <Stack data-testid="ONBOARDING_CALL" spacing={4}>
        <Typography variant="h4" showSkeleton={isLoading}>
          Onboarding call scheduling
        </Typography>
        <OnboardingCallInfo
          type={onboardingCall.type}
          callDuration={onboardingCall.duration}
          isLoading={isLoading}
          datetime={onboardingCall.datetime}
        />
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          spacing={2}
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            maxWidth={{ xs: '100%', md: '50%' }}
          >
            <Icon
              name="alert-circle-outline"
              size="large"
              fill={theme.palette.text.hint}
              showSkeleton={isLoading}
            />
            <Typography variant="caption" showSkeleton={isLoading}>
              To reschedule this appointment, please cancel it and remember to
              schedule a new appointment afterwards.
            </Typography>
          </Stack>
          <Button
            data-testid="CANCEL_APPOINTMENT_BUTTON"
            variant="text"
            onClick={handleConfirmCanceling}
            color="error"
            showSkeleton={isLoading}
            startEvaIcon={{
              name: 'slash-outline',
              size: 'large',
            }}
          >
            Cancel appointment
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default OnboardingCall;
