import React, { useMemo } from 'react';
import { Box, Paper, SxProps, Theme, useTheme } from '@mui/material';
import { DraftFillingStatus } from '@fdha/graphql-api-sitestaff';
import {
  ConditionalWrapper,
  Icon,
  IconProps,
  Link,
  Typography,
} from '@fdha/web-ui-library';
import { draftFillingStatusLabelByValue } from '@utils';

type PendingActionType =
  | 'PendingOnboardingCall'
  | 'PendingProfileFilling'
  | 'PendingProfileSubmission';

export interface PendingActionItem {
  type: PendingActionType;
}

export interface PendingBoxActionItem extends PendingActionItem {
  onClick: () => void;
}

export interface PendingInlineActionItem extends PendingActionItem {}

interface PendingActionsCommonProps {
  mode: 'box' | 'inline';
  showSkeleton?: boolean;
  sx?: SxProps<Theme>;
}

export type PendingActionsProps =
  | (PendingActionsCommonProps & {
      mode: 'box';
      pendingActionItems: PendingBoxActionItem[];
    })
  | (PendingActionsCommonProps & {
      mode: 'inline';
      pendingActionItems: PendingInlineActionItem[];
    });

interface Item {
  icon?: IconProps;
  label: string;
  key: string;
  link: ActionLink;
}

interface ActionLink {
  label: string;
  onClick?: () => void;
}

export const PendingActions: React.FC<PendingActionsProps> = ({
  mode,
  pendingActionItems,
  showSkeleton,
  sx,
}) => {
  const theme = useTheme();

  const isBoxMode = mode === 'box';
  const hasRightLinks = isBoxMode;
  const title = 'Pending actions';

  const defaultIcon: IconProps = {
    name: 'alert-circle-outline',
    fill: theme.palette.warning.dark,
    size: 'large',
  };

  const getItemByType = (
    pendingActionItem: PendingBoxActionItem | PendingInlineActionItem
  ): Item => {
    const { type } = pendingActionItem;
    const onClick =
      'onClick' in pendingActionItem ? pendingActionItem.onClick : undefined;

    switch (type) {
      case 'PendingOnboardingCall':
        return {
          label: 'Subject’s onboarding call has not been scheduled.',
          key: type,
          link: {
            label: 'Go to scheduling',
            onClick,
          },
        };
      case 'PendingProfileFilling':
        return {
          label: draftFillingStatusLabelByValue[DraftFillingStatus.Incomplete],
          key: type,
          link: {
            label: 'Edit draft',
            onClick,
          },
        };
      case 'PendingProfileSubmission':
        return {
          label: draftFillingStatusLabelByValue[DraftFillingStatus.Complete],
          key: type,
          link: {
            label: 'Submit profile',
            onClick,
          },
        };
    }
  };

  const actionItems: (Item | Partial<Item>)[] = useMemo(() => {
    const fakeItemForSkeleton: Partial<Item> = {
      key: 'loading',
    };

    return showSkeleton
      ? [fakeItemForSkeleton]
      : pendingActionItems.map((item) => getItemByType(item));
  }, [pendingActionItems, showSkeleton]);

  const renderBox = (children: React.ReactNode) => {
    if (showSkeleton) {
      return <Paper>{children}</Paper>;
    }

    return (
      <Paper
        sx={{
          backgroundColor: theme.palette.warning.lightBg,
          borderColor: theme.palette.warning.main,
        }}
      >
        {children}
      </Paper>
    );
  };

  const renderTitle = () => {
    switch (mode) {
      case 'inline': {
        return (
          <Typography
            variant="caption"
            textTransform="uppercase"
            color={theme.palette.text.secondary}
            fontWeight="bold"
            showSkeleton={showSkeleton}
          >
            {title}
          </Typography>
        );
      }
      case 'box':
        return (
          <Typography
            data-testid="PENDING_ACTIONS_TITLE"
            variant="h6"
            fontWeight="bold"
            showSkeleton={showSkeleton}
          >
            {title}
          </Typography>
        );
    }
  };

  const renderItem = (item: Item | Partial<Item>) => {
    const icon = item.icon ?? defaultIcon;

    return (
      <Box
        data-testid="PENDING_ACTIONS_SUBTITLE"
        key={item.key}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        columnGap={1}
      >
        <Box display="flex" columnGap={1} alignItems="center" flex={1}>
          <Icon {...icon} showSkeleton={showSkeleton} />
          <Typography
            variant={isBoxMode ? 'subtitle1' : 'body2'}
            color={theme.palette.text.secondary}
            showSkeleton={showSkeleton}
          >
            {item.label}
          </Typography>
        </Box>
        <Box minWidth={theme.spacing(8)}>
          {hasRightLinks && (
            <Link
              component="button"
              variant="subtitle1"
              fontWeight="bold"
              onClick={item.link?.onClick}
              showSkeleton={showSkeleton}
              data-testid="PENDING_ACTIONS_LINK"
            >
              {item.link?.label}
            </Link>
          )}
        </Box>
      </Box>
    );
  };

  if (!actionItems.length) {
    return null;
  }

  return (
    <ConditionalWrapper
      showWrapper={isBoxMode}
      wrapper={(children) => renderBox(children)}
    >
      <Box
        display="flex"
        flexDirection="column"
        rowGap={isBoxMode ? 2 : 1}
        sx={sx}
        data-testid="PENDING_ACTIONS_BOX"
      >
        {renderTitle()}
        {actionItems.map((item) => renderItem(item))}
      </Box>
    </ConditionalWrapper>
  );
};
