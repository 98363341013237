import React, { useEffect } from 'react';
import { AppBar, BasePage, SplashLoader } from '@components';
import { useActivityDetector, useAnalytics } from '@fdha/common-hooks';
import {
  Home,
  ResetPassword,
  ProfileChangePassword,
  Trial,
  AddSubject,
  SubjectProfile,
  EditSubject,
  OnboardingCallScheduling,
} from '@pages';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import {
  useGetProfileQuery,
  useGetSiteTrialsFromSiteStaffUserQuery,
} from '@fdha/graphql-api-sitestaff';
import { NetworkStatus } from '@apollo/client';

export const privatePageNamesByRoute = [
  { route: '/trials', name: 'Home' },
  { route: '/trials/[:trialId]', name: 'Trial' },
  { route: '/trials/[:trialId]/add-subject', name: 'Add new subject' },
  { route: '/trials/[:trialId]/[:profileId]', name: 'Profile' },
  {
    route: '/trials/[:trialId]/[:profileId]/edit-draft',
    name: 'Edit draft',
  },
  {
    route: '/trials/[:trialId]/[:profileId]/onboarding-call-scheduling',
    name: 'Onboarding call',
  },
  { route: '/change-password', name: 'Change Password' },
  { route: '/reset-password', name: 'Reset Password' },
];

export const Private = () => {
  const { init: initActivityDetector } = useActivityDetector();
  const { data, loading: loadingProfile } = useGetProfileQuery();
  const { analyticsClient } = useAnalytics();
  const { pathname } = useLocation();

  const { data: trialsData, networkStatus: trialsNetworkStatus } =
    useGetSiteTrialsFromSiteStaffUserQuery({
      variables: { hideTerminatedTrials: true },
    });

  useEffect(() => {
    initActivityDetector();
  }, [initActivityDetector]);

  useEffect(() => {
    analyticsClient?.page();
  }, [analyticsClient, pathname]);

  const loadingTrials = trialsNetworkStatus === NetworkStatus.loading;

  if (loadingProfile || loadingTrials) {
    return <SplashLoader />;
  }

  const staffName = data?.me.name || '';
  const staffPicture = data?.me.picture || '';
  const trials = trialsData?.siteTrials || [];

  return (
    <>
      <AppBar userName={staffName} userPicture={staffPicture} trials={trials} />
      <Routes>
        <Route path="/trials">
          <Route index element={<Home trials={trials} />} />
          <Route path=":trialId">
            <Route index element={<Trial />} />
            <Route path="add-subject" element={<AddSubject />} />
            <Route path=":profileId">
              <Route index element={<SubjectProfile />} />
              <Route path="edit-draft" element={<EditSubject />} />
              <Route
                path="onboarding-call-scheduling"
                element={<OnboardingCallScheduling />}
              />
            </Route>
          </Route>
        </Route>
        <Route
          path="/change-password"
          element={<ProfileChangePassword email={data?.me.email} />}
        />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/" element={<Navigate to="/trials" replace />} />
        <Route path="*" element={<BasePage type="notFound" />} />
      </Routes>
    </>
  );
};
