import { useDialog, useLoadingBar, useSnackbar } from '@fdha/web-ui-library';
import {
  AddSubjectInput,
  GetSiteTrialsFromSiteStaffUserDocument,
  useAddSubjectMutation,
} from '@fdha/graphql-api-sitestaff';
import { parseBackendError } from '@fdha/common-utils';
import { useNavigate } from 'react-router';

export const useSaveSubject = () => {
  const { showSnackbarV2 } = useSnackbar();
  const { openDialogV2, closeDialog } = useDialog();
  const { showLoadingV2, hideLoading } = useLoadingBar();
  const navigate = useNavigate();

  const [addSubject] = useAddSubjectMutation({
    refetchQueries: [GetSiteTrialsFromSiteStaffUserDocument],
  });

  const handleSuccess = (
    id?: string,
    trialId?: string,
    onboardingCallNeeded?: boolean | null
  ) => {
    if (id) {
      if (trialId && onboardingCallNeeded) {
        navigate(`/trials/${trialId}/${id}/onboarding-call-scheduling`, {
          replace: true,
        });
      } else {
        navigate(`../${id}`);
      }
    } else {
      // Fallback in case BE does not return patientId
      navigate('/');
    }
  };

  const showConfirmationDialog = (
    input: AddSubjectInput,
    hasOnboarding: boolean
  ) => {
    const onboardingMessage = hasOnboarding
      ? '\n\nOn the next screen, you will be able to schedule their onboarding call with Faeth.'
      : '';

    openDialogV2({
      title: 'Submit subject profile?',
      content: `After submission, the subject will receive an email containing instructions on how to complete the setup of their Faeth app user account.${onboardingMessage}`,
      confirmButtonLabel: 'Submit profile',
      cancelButtonLabel: 'Cancel',
      handleConfirm: async () => {
        handleSaveSubject(input);
        closeDialog();
      },
    });
  };

  const handleSaveSubject = async (input: AddSubjectInput) => {
    showLoadingV2();

    try {
      const result = await addSubject({
        variables: {
          input,
        },
      });
      showSnackbarV2({
        message: 'The subject’s profile was submitted succesfully',
        severity: 'success',
      });

      handleSuccess(
        result.data?.addSubject.id,
        input.trialId,
        result.data?.addSubject.onboardingCallNeeded
      );
    } catch (error) {
      const message = parseBackendError(error, 'Error adding subject');

      showSnackbarV2({
        message,
        severity: 'error',
      });
    } finally {
      hideLoading();
    }
  };

  return { showConfirmationDialog, handleSuccess };
};
