import { SubjectStatus } from '@fdha/graphql-api-sitestaff';
import {
  Chip,
  ChipColor,
  ChipProps,
  SkeletonWrapper,
} from '@fdha/web-ui-library';
import React, { useMemo } from 'react';

interface SubjectTrialStatusChipProps extends ChipProps {
  subjectStatus?: SubjectStatus | null;
  showSkeleton?: boolean;
}

const subjectStatusByValue = (subjectStatus?: SubjectStatus | null) => {
  let label: string;
  let color: ChipColor;

  switch (subjectStatus) {
    case SubjectStatus.StudyCompleted:
      label = 'Study completed';
      color = 'secondary';
      break;
    case SubjectStatus.Ongoing:
      label = 'Ongoing';
      color = 'success';
      break;
    case SubjectStatus.Screening:
      label = 'Screening';
      color = 'info';
      break;
    case SubjectStatus.Discontinued:
      label = 'Discontinued';
      color = 'error';
      break;
    case SubjectStatus.Draft:
      label = 'Draft mode';
      color = 'warning';
      break;
    case SubjectStatus.ScreenFailed:
      label = 'Screen failed';
      color = 'error';
      break;
    default:
      label = 'error';
      color = 'error';
  }
  return { label, color };
};

export const SubjectTrialStatusChip = ({
  subjectStatus,
  showSkeleton = false,
  ...props
}: SubjectTrialStatusChipProps) => {
  const subjectStatusChipProps = useMemo(
    () => subjectStatusByValue(subjectStatus),
    [subjectStatus]
  );

  if (!showSkeleton && !subjectStatus) return null;

  return (
    <SkeletonWrapper isLoading={showSkeleton} fullWidth variant="rounded">
      <Chip
        label={subjectStatusChipProps.label}
        color={subjectStatusChipProps.color}
        data-testid="CHIP"
        variant="outlined"
        {...props}
      />
    </SkeletonWrapper>
  );
};
