import { BasePage } from '@components';
import { ChangePasswordV2 } from '@fdha/web-auth';
import React from 'react';

export const ChangePassword = () => {
  return (
    <BasePage
      type="default"
      contentSize="xsmall"
      verticallyCentered={true}
      headerProps={{
        title: 'Set Password',
        showBack: false,
        showLogo: true,
        centeredHeader: true,
      }}
    >
      <ChangePasswordV2 showTosToggle />
    </BasePage>
  );
};
