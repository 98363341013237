import React from 'react';
import { createRoot } from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { apolloClientConfig } from '@config';
import fdhaConfig from '@fdha/aws-config-sitestaff';
import {
  DialogProvider,
  ErrorFallback,
  LoadingBarProvider,
  SnackbarProvider,
  themeV2,
} from '@fdha/web-ui-library';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { TranslationProvider } from '@fdha/common-hooks';

import reportWebVitals from './reportWebVitals';
import App from './App';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: fdhaConfig.cognito.siteStaffUserPoolId,
      userPoolClientId: fdhaConfig.cognito.siteStaffUserPoolClientId,
    },
  },
});

const apolloClient = new ApolloClient(apolloClientConfig);

const container = document.getElementById('root');
if (!container) throw new Error('Root element not found');

const root = createRoot(container);

root.render(
  <TranslationProvider>
    <ThemeProvider theme={themeV2}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ApolloProvider client={apolloClient}>
          <SnackbarProvider>
            <DialogProvider>
              <LoadingBarProvider>
                <CssBaseline />
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </LoadingBarProvider>
            </DialogProvider>
          </SnackbarProvider>
        </ApolloProvider>
      </ErrorBoundary>
    </ThemeProvider>
  </TranslationProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
