import { CustomField, SubjectDraft } from '@fdha/graphql-api-sitestaff';
import {
  AddressInputFields,
  AddressValidationStatus,
  addNotInFutureDateValidation,
  addPhoneNumberValidation,
  confirmEmailValidation,
  emailValidation,
  formatUTCDate,
  getCustomFieldsValidationSchema,
  heightValidation,
  maskPhone,
  requiredMessage,
  subjectIdValidation,
  weightValidation,
} from '@fdha/web-ui-library';
import * as Yup from 'yup';

import {
  physicalActivitySelectValueByValue,
  userGenderLabelByValue,
} from './labelByValueMappers';

export interface SubjectSchema {
  firstName: string;
  lastName: string;
  subjectId: string;
  gender: string;
  birthdate: Date | null;
  weight: string;
  height: string;
  physicalActivityLevel: string;
  address: AddressInputFields;
  addressValidationStatus: AddressValidationStatus;
  complement: string;
  phoneNumber: string;
  email: string;
  confirmEmail: string;
  hasCaregiver: string;
  caregiverName: string;
  caregiverEmail: string;
  questions: string;
  treatmentStartDate: Date | null;
  language: string | undefined;
}

const getSubjectValidationSchema = (hasLanguage: boolean) => {
  let baseSchema = Yup.object().shape({
    firstName: Yup.string().trim().required(requiredMessage),
    lastName: Yup.string().trim().required(requiredMessage),
    subjectId: subjectIdValidation.required(requiredMessage),
    gender: Yup.string().trim().required(requiredMessage),
    birthdate: addNotInFutureDateValidation().required(requiredMessage),
    weight: weightValidation.required(requiredMessage),
    height: heightValidation.required(requiredMessage),
    physicalActivityLevel: Yup.string().trim().required(requiredMessage),
    address: Yup.object().test(
      'address',
      requiredMessage,
      (address) => !!address.formatted
    ),
    phoneNumber: addPhoneNumberValidation().required(requiredMessage),
    email: emailValidation.required(requiredMessage),
    confirmEmail: confirmEmailValidation.required(requiredMessage),
    caregiverEmail: emailValidation,
  });
  if (hasLanguage) {
    baseSchema = baseSchema.concat(
      Yup.object().shape({
        language: Yup.string().trim().required(requiredMessage),
      })
    );
  }
  return baseSchema;
};

const draftValidationSchema = Yup.object().shape({
  firstName: Yup.string().trim().required(requiredMessage),
  lastName: Yup.string().trim().required(requiredMessage),
  subjectId: subjectIdValidation,
  birthdate: addNotInFutureDateValidation(),
  weight: weightValidation,
  height: heightValidation,
  phoneNumber: addPhoneNumberValidation(),
  email: emailValidation,
  confirmEmail: confirmEmailValidation,
  caregiverEmail: emailValidation,
});

export const getValidationSchema = (
  isDraft: boolean,
  hasLanguage: boolean,
  customFields?: CustomField[]
) => {
  const customFieldsValidation = getCustomFieldsValidationSchema(
    isDraft,
    customFields
  );

  return isDraft
    ? draftValidationSchema.concat(customFieldsValidation)
    : getSubjectValidationSchema(hasLanguage).concat(customFieldsValidation);
};

export const getInitialValues = (subject?: SubjectDraft): SubjectSchema => {
  const date = formatUTCDate(subject?.birthdate);
  const treatmentStartDate = formatUTCDate(subject?.treatmentStartDate);

  return {
    firstName: subject?.firstName || '',
    lastName: subject?.lastName || '',
    subjectId: subject?.subjectId
      ? subject.subjectId.substring(subject.subjectId.length - 3)
      : '',
    gender: subject?.gender ? userGenderLabelByValue[subject.gender] : '',
    birthdate: date ? new Date(date) : null,
    weight:
      subject?.weight && subject.weight !== 0 ? subject.weight.toString() : '',
    height:
      subject?.height && subject.height !== 0 ? subject.height.toString() : '',
    physicalActivityLevel: subject?.physicalActivityLevel
      ? physicalActivitySelectValueByValue[subject.physicalActivityLevel]
      : '',
    address: {
      formatted: subject?.address?.formatted || '',
      country: subject?.address?.country || '',
      region: subject?.address?.region || '',
      streetAddress: subject?.address?.street_address || '',
      postalCode: subject?.address?.postal_code || '',
      locality: subject?.address?.locality || '',
    },
    addressValidationStatus:
      subject?.address?.addressValidationStatus || AddressValidationStatus.Ok,
    complement: subject?.address?.complement || '',
    phoneNumber: maskPhone(subject?.phoneNumber),
    email: subject?.email || '',
    confirmEmail: subject?.emailConfirmation || '',
    hasCaregiver:
      subject?.caregiver?.hasCaregiver == null
        ? ''
        : subject.caregiver.hasCaregiver
        ? 'yes'
        : 'no',
    caregiverName: subject?.caregiver?.name || '',
    caregiverEmail: subject?.caregiver?.primary_caregiver_email || '',
    questions: subject?.questions || '',
    treatmentStartDate: treatmentStartDate
      ? new Date(treatmentStartDate)
      : null,
    language: subject?.language || '',
  };
};

export const languageInfoText =
  'Patient-facing resources, such as the Faeth App, will present content based on this language setting, if supported.';
