import {
  DraftFillingStatus,
  PhysicalActivityLevel,
  UserGender,
} from '@fdha/graphql-api-sitestaff';

export const draftFillingStatusLabelByValue: Record<
  DraftFillingStatus,
  string
> = {
  [DraftFillingStatus.Complete]:
    'All required data is filled and profile is ready to be submitted.',
  [DraftFillingStatus.Incomplete]:
    'Some required data is missing and needs to be filled before you can submit this profile.',
};

export const physicalActivityLabelByValue: Record<
  PhysicalActivityLevel,
  string
> = {
  [PhysicalActivityLevel.Sedentary]: 'Sedentary (little to no exercise)',
  [PhysicalActivityLevel.SlightlyActive]:
    'Slightly active (light exercise/sports)',
  [PhysicalActivityLevel.Moderately]: 'Moderately active (3-5 days/week)',
  [PhysicalActivityLevel.VeryActive]: 'Very active (6-7 days/week)',
};

export const physicalActivitySelectValueByValue: Record<
  PhysicalActivityLevel,
  string
> = {
  [PhysicalActivityLevel.Sedentary]: 'Sedentary',
  [PhysicalActivityLevel.SlightlyActive]: 'SlightlyActive',
  [PhysicalActivityLevel.Moderately]: 'Moderately',
  [PhysicalActivityLevel.VeryActive]: 'VeryActive',
};

export const userGenderLabelByValue: Record<UserGender, string> = {
  [UserGender.Female]: 'Female',
  [UserGender.Male]: 'Male',
};
