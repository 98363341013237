import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BasePage, PendingActions, SubjectTrialStatusChip } from '@components';
import {
  RegistrationStatus,
  useGetCustomFieldsValidationQuery,
  useGetPatientUserQuery,
  useGetScheduledOnboardingCallQuery,
  useResendOneTimePasswordMutation,
} from '@fdha/graphql-api-sitestaff';
import { Paper, Stack } from '@mui/material';
import { parseBackendError } from '@fdha/common-utils';
import { isPast, parseISO } from 'date-fns';
import { isMissingOnboardingCall } from '@fdha/common-utils';
import {
  Button,
  useDialog,
  useLoadingBar,
  useSnackbar,
  shouldShowCustomField,
} from '@fdha/web-ui-library';

import { ProfileInformation } from './ProfileInformation';
import OnboardingCall from './OnboardingCall';

export const PatientProfile = () => {
  const { showSnackbarV2 } = useSnackbar();
  const { openDialogV2, closeDialog } = useDialog();
  const { showLoadingV2, hideLoading } = useLoadingBar();

  const params = useParams();
  const navigate = useNavigate();

  const { profileId, trialId = '' } = params;

  const { data: subjectData, loading: loadingSubjectData } =
    useGetPatientUserQuery({
      variables: { patientId: profileId || '' },
      fetchPolicy: 'cache-and-network',
    });

  const [resendOneTimePassword] = useResendOneTimePasswordMutation({
    variables: { patientId: profileId || '' },
  });

  const { data: customFieldsData, loading: loadingCustomFields } =
    useGetCustomFieldsValidationQuery({
      variables: { trialId },
    });

  const subject = subjectData?.patientUser;
  const isOnboardingCallScheduled = !!subject?.onboardingCallScheduled;
  const customFields = customFieldsData?.customFieldsValidation;

  const { data: onboardingCallData, loading: loadingOnboardingCall } =
    useGetScheduledOnboardingCallQuery({
      variables: { patientId: profileId || '' },
      fetchPolicy: 'cache-and-network',
      skip: !isOnboardingCallScheduled,
    });

  const onboardingCall = onboardingCallData?.scheduledOnboardingCall;
  const isLoading =
    loadingSubjectData || loadingOnboardingCall || loadingCustomFields;

  const onClickScheduleOnboardingCall = () => {
    navigate(
      `/trials/${subject?.trial?.id}/${subject?.id}/onboarding-call-scheduling`
    );
  };

  const showOnboardingCall = useMemo(
    () =>
      subject?.onboardingCallScheduled &&
      onboardingCall &&
      !isPast(parseISO(onboardingCall.datetime)),
    [subject?.onboardingCallScheduled, onboardingCall]
  );

  const showSendOneTimePassword =
    subjectData?.patientUser.registrationStatus ===
    RegistrationStatus.ForceChangePassword;

  const showTreatmentStartDate = shouldShowCustomField(
    'treatmentStartDate',
    customFields,
    isLoading
  );

  const showSendOneTimePasswordDialog = () => {
    openDialogV2({
      title: 'Send a new one-time password?',
      content: `By sending a new one-time password, the patient will be sent an email containing a new password to be used for their initial log-in to the app. The new one-time password will expire 7 days after being issued. All previously issued one-time passwords to log into their account will be invalidated.\n\nDo you want to continue?`,
      confirmButtonLabel: 'Yes, send a new one-time password',
      cancelButtonLabel: 'Cancel',
      handleConfirm: async () => {
        closeDialog();
        showLoadingV2();

        try {
          await resendOneTimePassword();

          showSnackbarV2({
            message:
              'A new one-time password has been sent to the patient’s email.',
            severity: 'success',
          });
        } catch (error) {
          const message = parseBackendError(
            error,
            'Error sending one-time password'
          );

          showSnackbarV2({
            message,
            severity: 'error',
          });
        } finally {
          hideLoading();
        }
      },
    });
  };

  const hasPendingOnboardingCall = useMemo(() => {
    return isMissingOnboardingCall(
      subject?.subjectTrialStatus,
      subject?.onboardingCallNeeded,
      subject?.onboardingCallScheduled
    );
  }, [
    subject?.onboardingCallNeeded,
    subject?.onboardingCallScheduled,
    subject?.subjectTrialStatus,
  ]);

  return (
    <BasePage
      type="default"
      isLoading={isLoading}
      headerProps={{
        title: subject?.name,
        subtitle: subject?.subject_id || '',
      }}
      navigationProps={{ type: 'breadcrumb' }}
    >
      <Stack spacing={2}>
        <PendingActions
          mode="box"
          pendingActionItems={
            hasPendingOnboardingCall
              ? [
                  {
                    type: 'PendingOnboardingCall',
                    onClick: onClickScheduleOnboardingCall,
                  },
                ]
              : []
          }
          showSkeleton={isLoading}
        />
        <SubjectTrialStatusChip
          subjectStatus={subject?.subjectTrialStatus}
          showSkeleton={isLoading}
          sx={{ width: '100%' }}
          size="medium"
        />
        {!!showOnboardingCall && onboardingCall && (
          <OnboardingCall
            onboardingCall={onboardingCall}
            patientId={profileId ?? ''}
            isLoading={isLoading}
          />
        )}
        <ProfileInformation
          isLoading={isLoading}
          trialAbbreviation={subject?.trial?.protocol_abbreviation}
          name={subject?.name}
          subjectId={subject?.subject_id}
          birthdate={subject?.birthdate}
          weight={subject?.weight}
          height={subject?.height}
          gender={subject?.gender}
          physicalActivityLevel={subject?.physical_activity_level}
          address={subject?.address}
          email={subject?.email}
          phoneNumber={subject?.phone_number}
          caregiver={subject?.caregiver}
          treatmentStartDate={subject?.treatmentStartDate}
          showTreatmentStartDate={showTreatmentStartDate}
          language={subject?.language}
        />
        {showSendOneTimePassword && (
          <Paper sx={{ textAlign: { md: 'left', xs: 'center' } }}>
            <Button
              variant="outlined"
              onClick={showSendOneTimePasswordDialog}
              startEvaIcon={{
                name: 'unlock-outline',
              }}
            >
              Send a new one-time password
            </Button>
          </Paper>
        )}
      </Stack>
    </BasePage>
  );
};
